import {
  ApprovalState,
  Attachment,
  Comment,
  DealSharing,
  DealType,
  DueDiligenceCheckListFormData,
  DueDiligenceState,
  Post,
  PostType,
  SMSMemoRating,
  ServerTimestamp,
  commentConverter,
  convertAttachmentToFirebaseModel,
  convertAttachmentsToFirebaseModel,
  dealSharingConverter,
  postConverter,
  scoutConverter,
  smsMemoRatingConverter,
} from "@sequoiacap/shared/models";
import { DueDiligenceFormDataV2 } from "@sequoiacap/shared/models/due-diligence-question-v2";
import {
  FIREBASE_WRITE_TIMEOUT,
  FunctionNameV2,
  useGetFromCloudFunctionInSuperJSON,
  useReadDocument,
} from "./firebase/FirebaseAPI";
import { FileUploadInfo } from "~/components/deal/shared/MultiFilePicker";
import { PendingComment } from "./comment-api";
import { ReadDocumentReturnType } from "./firebase/types";
import {
  TrackErrorEvent,
  TrackEvent,
  track,
  trackError,
} from "~/utils/analytics";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { fuego, mutateDocument } from "./swr-firebase";
import { getStorageFileDownloadURL } from "./swr-firebase/hooks/use-swr-storage";
import { isEqual } from "lodash";
import { omitUndefined } from "@sequoiacap/shared/utils/omitUndefined";
import { useAPIGetUser } from "./user-api";
import { useMemo } from "react";
import getFirebaseDataConverter, {
  cloneToFirebaseDb,
} from "./firebase/firebase-data-converter";

export type PendingMemo = {
  companyName: string;
  companyUrl?: string;
  whatCompanyDoes?: string;
  cofounderLinkedIn: string[];
  pitchDeckUrl?: string;
  isPrivate?: boolean;
  isRequestFeedback?: boolean;
  uploadFiles?: FileUploadInfo[];
  securedLeadInvestor: string;
  whyInvest?: string;
  memo: {
    title: string;

    /** iOS pre 1.22 will also display this */
    bodyForSearch: string;

    /** Edit and display (PostCell web+iOS, email) */
    rawBody: string;
    hashtag: string[];
    mention: Record<string, { id: string; name: string }>;
    attachment?: Record<string, Attachment>;
    attributes?: Record<string, string>;
  };
  dueDiligence:
    | DueDiligenceCheckListFormData
    | DueDiligenceFormDataV2
    | undefined;
  dueDiligenceState: DueDiligenceState;
};

export async function createMemo(
  draftId: string,
  memo: PendingMemo,
  loggedInUserId: string,
  investorId: string,
  groupId: string,
  formVersion = 1,
): Promise<[DealSharing, Post] | undefined> {
  const files: Attachment[] = await Promise.all(
    (memo.uploadFiles ?? []).map(async (info) => {
      const storagePath = await info.storagePath;
      const url = await getStorageFileDownloadURL(storagePath);
      return new Attachment(
        storagePath,
        info.name,
        storagePath,
        info.type,
        url,
      );
    }),
  );
  console.log("createMemo/", memo, loggedInUserId, investorId, groupId, files);

  const path = `deal_sharing/${draftId}`;
  const newDeal = new DealSharing(
    draftId,
    loggedInUserId,
    DealType.memo,
    investorId,
    {
      companyName: memo.companyName,
      companyUrl: memo.companyUrl,
      whatCompanyDoes: memo.whatCompanyDoes,
      cofounderLinkedIn: memo.cofounderLinkedIn,
      pitchDeckUrl: memo.pitchDeckUrl,
      securedLeadInvestor: memo.securedLeadInvestor,
      whyInvest: memo.whyInvest,
      formVersion,
    },
    ServerTimestamp.create(),
    ServerTimestamp.create(),
    memo.dueDiligenceState,
    ServerTimestamp.create(),
    memo.isRequestFeedback
      ? ApprovalState.requestFeedback
      : ApprovalState.pending,
    undefined,
    undefined,
    groupId,
    memo.dueDiligence,
    ServerTimestamp.create(),
    memo.memo.rawBody,
    files,
    undefined,
    undefined,
    undefined,
    [
      {
        to: memo.isRequestFeedback
          ? ApprovalState.requestFeedback
          : ApprovalState.pending,
        createdById: loggedInUserId,
        createdAt: new Date(), // NOTE: firebase doesn't allow serverTimestamp() inside array
      },
    ],
  );
  const postPath = `post/${draftId}`;
  const newPost = new Post(
    draftId,
    memo.memo.title,
    groupId,
    ServerTimestamp.create(),
    loggedInUserId,
    PostType.regular,
    memo.memo.bodyForSearch,
    omitUndefined(memo.memo.mention),
    undefined,
    memo.memo.attachment,
    ServerTimestamp.create(),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    memo.memo.hashtag,
    undefined,
    true,
    undefined,
    undefined,
    formVersion === 2,
  );

  track(TrackEvent.submittingMemo, {
    id: newDeal.id,
    investorId,
    dealType: DealType.memo,
    dueDiligenceState: memo.dueDiligenceState,
    approvalState: newDeal.approvalState,
  });

  try {
    const batch = writeBatch(fuego.db);
    batch.set(
      doc(fuego.db, path).withConverter(
        getFirebaseDataConverter(dealSharingConverter),
      ),
      newDeal,
    );

    batch.set(
      doc(fuego.db, postPath).withConverter(
        getFirebaseDataConverter(postConverter),
      ),
      newPost,
    );
    await asyncCallWithTimeout(batch.commit(), FIREBASE_WRITE_TIMEOUT);
    track(TrackEvent.submittedMemo, {
      id: newDeal.id,
      investorId,
      dealType: DealType.memo,
      approvalState: newDeal.approvalState,
    });
  } catch (err) {
    console.error("createMemo/error", err);
    trackError(TrackErrorEvent.submitMemo, err, {
      id: newDeal.id,
      investorId,
      dealType: DealType.memo,
      approvalState: newDeal.approvalState ?? "",
      error: `${err}`,
    });
    throw err;
  }

  const dealFromServer = await mutateDocument(path, dealSharingConverter, true);
  const postFromServer = await mutateDocument(postPath, postConverter, true);
  if (!dealFromServer) {
    throw new Error("Error saving deal");
  }
  if (!postFromServer) {
    throw new Error("Error saving post");
  }

  return [dealFromServer, postFromServer];
}

export async function updateMemo(
  currentMemo: DealSharing,
  currentPost: Post | undefined,
  memo: PendingMemo,
  loggedInUserId: string,
  formVersion = 1,
): Promise<[DealSharing, Post] | undefined> {
  const files: Attachment[] = await Promise.all(
    (memo.uploadFiles ?? []).map(async (info) => {
      const storagePath = await info.storagePath;
      const url =
        info.downloadURL ?? (await getStorageFileDownloadURL(storagePath));
      return new Attachment(
        storagePath,
        info.name,
        storagePath,
        info.type,
        url,
      );
    }),
  );
  console.log("updateMemo/", currentMemo.id, memo, files);

  const dealPath = `deal_sharing/${currentMemo.id}`;
  const postPath = `post/${currentMemo.id}`;

  const updateDealValue: Record<string, unknown> = {
    meta: {
      companyName: memo.companyName,
      companyUrl: memo.companyUrl,
      whatCompanyDoes: memo.whatCompanyDoes,
      cofounderLinkedIn: memo.cofounderLinkedIn,
      pitchDeckUrl: memo.pitchDeckUrl,
      securedLeadInvestor: memo.securedLeadInvestor,
      whyInvest: memo.whyInvest,
      formVersion,
    },
    approval_state:
      currentMemo.approvalState === ApprovalState.approved
        ? ApprovalState.approved
        : memo.isRequestFeedback
          ? ApprovalState.requestFeedback
          : ApprovalState.pending,
    due_diligence_state: memo.dueDiligenceState,
    due_diligence: memo.dueDiligence,
    memo: memo.memo.rawBody,
    uploaded_file: convertAttachmentsToFirebaseModel(files),
    updated_at: ServerTimestamp.create(),
  };
  if (currentMemo.dueDiligenceState !== memo.dueDiligenceState) {
    updateDealValue.due_diligence_state_updated_at = ServerTimestamp.create();
  }
  if (!isEqual(currentMemo.dueDiligence, memo.dueDiligence)) {
    updateDealValue.due_diligence_updated_at = ServerTimestamp.create();
  }

  const updatePostValue = !!currentPost
    ? {
        title: memo.memo.title,
        body: memo.memo.bodyForSearch,
        hashtag: memo.memo.hashtag ?? [],
        mention: omitUndefined(memo.memo.mention) ?? {},
        attachment: convertAttachmentToFirebaseModel(memo.memo.attachment),
        updated_at: serverTimestamp(),
      }
    : undefined;

  const newPost = !currentPost
    ? new Post(
        currentMemo.id,
        memo.memo.title,
        currentMemo.groupId ?? "",
        ServerTimestamp.create(),
        currentMemo.createdById,
        PostType.regular,
        memo.memo.bodyForSearch,
        omitUndefined(memo.memo.mention),
        undefined,
        memo.memo.attachment,
        ServerTimestamp.create(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        memo.memo.hashtag,
        undefined,
        true,
        undefined,
      )
    : undefined;

  track(TrackEvent.updatingMemo, {
    id: currentMemo.id,
    investorId: currentMemo.investorId,
    dealType: DealType.memo,
    dueDiligenceState: memo.dueDiligenceState,
    approvalState: currentMemo.approvalState,
  });

  const prevApprovalState = currentMemo.approvalState;
  const newApprovalState = updateDealValue.approval_state;

  try {
    const batch = writeBatch(fuego.db);
    batch.update(doc(fuego.db, dealPath), {
      ...cloneToFirebaseDb(updateDealValue),
      ...(prevApprovalState !== newApprovalState && {
        approval_state_change: arrayUnion({
          ...(prevApprovalState && { from: prevApprovalState }),
          to: newApprovalState,
          created_by_id: loggedInUserId,
          created_at: new Date(), // NOTE: firebase doesn't allow serverTimestamp() inside array
        }),
      }),
    });
    if (updatePostValue) {
      batch.update(doc(fuego.db, postPath), updatePostValue);
    } else if (newPost) {
      batch.set(
        doc(fuego.db, postPath).withConverter(
          getFirebaseDataConverter(postConverter),
        ),
        newPost,
      );
    }
    await asyncCallWithTimeout(batch.commit(), FIREBASE_WRITE_TIMEOUT);
    track(TrackEvent.updatingMemo, {
      id: currentMemo.id,
      investorId: currentMemo.investorId,
      dealType: DealType.memo,
      dueDiligenceState: memo.dueDiligenceState,
      approvalState: currentMemo.approvalState,
    });
  } catch (err) {
    console.error(
      "updateMemo/error",
      err,
      updateDealValue,
      updatePostValue,
      newPost,
    );
    trackError(TrackErrorEvent.updateMemo, err, {
      id: currentMemo.id,
      investorId: currentMemo.investorId,
      dealType: DealType.memo,
      error: `${err}`,
    });
    throw err;
  }

  const dealFromServer = await mutateDocument(
    dealPath,
    dealSharingConverter,
    true,
  );
  const postFromServer = await mutateDocument(postPath, postConverter, true);
  if (!dealFromServer) {
    throw new Error("Error saving deal");
  }
  if (!postFromServer) {
    throw new Error("Error saving post");
  }
  return [dealFromServer, postFromServer];
}

export async function approveMemo(
  draftId: string,
  comment: PendingComment,
  smsMemoRating: SMSMemoRating | undefined,
  loggedInUserId: string,
): Promise<Comment> {
  console.log("approveMemo/", comment.postId, draftId);
  const commentPath = `post/${comment.postId}/comment/${draftId}`;
  const dealSharingPath = `deal_sharing/${comment.postId}`;
  const smsMemoRatingPath = `sms_memo_rating/${comment.postId}`;

  const updateDealValue = {
    approval_state: ApprovalState.approved,
    approval_state_updated_at: ServerTimestamp.create(),
    approved_by_id: loggedInUserId,
    updated_at: ServerTimestamp.create(),
  };

  const newComment = new Comment(
    draftId,
    comment.postId,
    comment.parentId,
    comment.body ?? "",
    ServerTimestamp.create(),
    loggedInUserId,
    ServerTimestamp.create(),
    undefined,
    undefined,
    undefined,
    undefined,
    comment.hashtag,
    comment.mention,
    comment.attachment,
  );

  track(TrackEvent.approvingMemo, {
    comment_id: draftId,
    post_id: comment.postId,
  });

  try {
    const batch = writeBatch(fuego.db);
    batch.update(doc(fuego.db, dealSharingPath), {
      ...cloneToFirebaseDb(updateDealValue),
      approval_state_change: arrayUnion({
        from: ApprovalState.pending,
        to: ApprovalState.approved,
        created_by_id: loggedInUserId,
        created_at: new Date(), // NOTE: firebase doesn't allow serverTimestamp() inside array
      }),
    });

    batch.set(
      doc(fuego.db, commentPath).withConverter(
        getFirebaseDataConverter(commentConverter),
      ),
      newComment,
    );
    if (smsMemoRating) {
      batch.set(
        doc(fuego.db, smsMemoRatingPath).withConverter(
          getFirebaseDataConverter(smsMemoRatingConverter),
        ),
        smsMemoRating,
      );
    }
    await asyncCallWithTimeout(batch.commit(), FIREBASE_WRITE_TIMEOUT);
    track(TrackEvent.approvedMemo, {
      comment_id: draftId,
      post_id: comment.postId,
    });
  } catch (err) {
    console.error(
      "approveMemo/error",
      err,
      updateDealValue,
      newComment,
      smsMemoRating,
    );
    trackError(TrackErrorEvent.approveMemo, err, {
      comment_id: draftId,
      post_id: comment.postId,
      error: `${err}`,
    });
    throw err;
  }

  await mutateDocument(dealSharingPath, dealSharingConverter, true);
  const commentFromServer = await mutateDocument(
    commentPath,
    commentConverter,
    true,
  );
  if (!commentFromServer) {
    throw new Error("Error saving comment");
  }

  return commentFromServer;
}

export function useAPIGetDeal(
  dealId: string | null,
): ReadDocumentReturnType<DealSharing> {
  return useReadDocument(
    dealId ? `deal_sharing/${dealId}` : null,
    dealSharingConverter,
    {},
  );
}

export type PendingShareOpportunity = {
  draftId: string;
  companyName: string;
  companyUrl?: string;
  whatCompanyDoes?: string;
  cofounderLinkedIn: string[];
  pitchDeckUrl?: string;
  topic?: string;
  files?: FileUploadInfo[];
};
export async function createShareOpportunity(
  opportunity: PendingShareOpportunity,
  loggedInUserId: string,
  investorId: string,
  dealType: DealType,
): Promise<DealSharing | undefined> {
  if (!hasValue(opportunity)) {
    return undefined;
  }

  const { draftId, files: filesPromises, ...meta } = opportunity;
  const files: Attachment[] = await Promise.all(
    (filesPromises ?? []).map(async (info) => {
      const storagePath = await info.storagePath;
      const url = await getStorageFileDownloadURL(storagePath);
      return new Attachment(
        storagePath,
        info.name,
        storagePath,
        info.type,
        url,
      );
    }),
  );
  console.log("createShareOpportunity/", opportunity, loggedInUserId, files);
  const path = `deal_sharing/${draftId}`;
  const newDeal = new DealSharing(
    draftId,
    loggedInUserId,
    dealType,
    investorId,
    meta,
    ServerTimestamp.create(),
    ServerTimestamp.create(),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    files,
  );
  track(TrackEvent.sharingOpportunity, {
    id: newDeal.id,
    investorId,
    dealType,
  });
  try {
    await asyncCallWithTimeout(
      setDoc(
        doc(fuego.db, path).withConverter(
          getFirebaseDataConverter(dealSharingConverter),
        ),
        newDeal,
      ),
      FIREBASE_WRITE_TIMEOUT,
    );
    track(TrackEvent.sharedOpportunity, {
      id: newDeal.id,
      investorId,
      dealType,
    });
  } catch (err) {
    console.error("createShareOpportunity/error", err);
    trackError(TrackErrorEvent.shareOpportunity, err, {
      id: newDeal.id,
      investorId,
      dealType,
      error: `${err}`,
    });
    throw err;
  }

  return mutateDocument(path, dealSharingConverter, true);
}

function hasValue(value: PendingShareOpportunity): boolean {
  return !(
    (value.companyName ?? "") === "" &&
    (value.cofounderLinkedIn?.length ?? 0) === 2 &&
    (value.cofounderLinkedIn?.[0] ?? "") === "" &&
    (value.cofounderLinkedIn?.[1] ?? "") === "" &&
    (value.companyUrl ?? "") === "" &&
    (value.topic ?? "") === "" &&
    (value.whatCompanyDoes ?? "") === "" &&
    (value.pitchDeckUrl ?? "") === "" &&
    (value.files?.length ?? 0) === 0
  );
}

export type ScoutInfo = {
  investorName: string;
  investorId: string;
  scoutGroupId: string | undefined;
  privateScoutGroupId?: string | null;
  serverFetched?: boolean;
  saf?: boolean;
};

export function useAPIGetScoutInfo(
  userId: string | undefined | null,
  prefetchedScoutInfo?: ScoutInfo,
): {
  data?: ScoutInfo;
  loading: boolean;
  error?: Error;
} {
  const isReady = !!userId;
  const {
    data,
    loading: scoutLoading,
    error: scoutLoadingError,
  } = useReadDocument(isReady ? `scout/${userId}` : null, scoutConverter, {});

  const {
    data: investor,
    loading: investorLoading,
    error: investorLoadingError,
  } = useAPIGetUser(data?.investorId ?? null);

  const scoutInfo =
    useMemo(() => {
      return isReady && data && investor
        ? {
            investorName: investor.name,
            investorId: investor.id,
            scoutGroupId: data.publicScoutGroupId,
            privateScoutGroupId: data.privateScoutGroupId,
            saf: data.saf,
          }
        : undefined;
    }, [data, investor, isReady]) ?? prefetchedScoutInfo;

  return {
    data: scoutInfo,
    loading: !scoutInfo && (scoutLoading || investorLoading),
    error: scoutLoadingError ?? investorLoadingError,
  };
}

export type CountSimilarLookingDealsRequest = {
  /** Current deal being edited (if any). This deal will be excepted from the count to allow editing the current deal */
  currentDealId?: string;
  cofounderLinkedIn?: string[];
  companyUrl?: string;
};

type CountSimilarLookingDealsResult = {
  cofounderLinkedIn: Record<string, number>;
  companyUrl: Record<string, number>;
};
export function useAPICountSimilarLookingDeals({
  currentDealId,
  cofounderLinkedIn,
  companyUrl,
}: CountSimilarLookingDealsRequest = {}): ReadDocumentReturnType<CountSimilarLookingDealsResult> {
  const functionName = FunctionNameV2.countSimilarLookingDeals;
  const { data, error } =
    useGetFromCloudFunctionInSuperJSON<CountSimilarLookingDealsResult>(
      !cofounderLinkedIn?.length && !companyUrl ? undefined : functionName,
      {
        currentDealId,
        cofounderLinkedIn,
        companyUrl,
      },
    );
  return {
    data,
    error,
    loading: !error && !data,
  };
}
